import * as React from 'react';
import { Box, Button } from '@dishr/design';
import Section from '../Section';
import SectionSubSection from '../SectionSubSection';
import SectionText from '../SectionText';

export default function ComponentsButtons() {
  return (
    <Section title="Buttons">
      <SectionSubSection title="Primary Buttons">
        <SectionText>
          Primary buttons should be used for actions that if a user wishes
          to proceed, they must click this button.
        </SectionText>

        <Box flex="horizontal" gap="x2" margin="x2">
          <Button color="blue" level="primary">Blue Primary Button</Button>
          <Button color="light" level="primary">Light Primary Button</Button>
          <Button color="red" level="primary">Red Primary Button</Button>
        </Box>
      </SectionSubSection>

      <SectionSubSection title="Secondary Buttons">
        <SectionText>
          Secondary buttons should be used for actions that are optional
          but are still prevalent on a page and require a bit of attention.
        </SectionText>

        <Box flex="horizontal" gap="x2" margin="x2">
          <Button color="blue" level="secondary">Blue Secondary Button</Button>
          <Button color="light" level="secondary">Light Secondary Button</Button>
          <Button color="red" level="secondary">Red Secondary Button</Button>
        </Box>
      </SectionSubSection>

      <SectionSubSection title="Tertiary Buttons">
        <SectionText>
          Tertiary buttons should be used for actions that a user can perform
          but it's minimal or within a crowded place, something that doesn't
          shout and cause chaos with the content around it.
        </SectionText>

        <Box flex="horizontal" gap="x2" margin="x2">
          <Button color="blue" level="tertiary">Blue Tertiary Button</Button>
          <Button color="light" level="tertiary">Light Tertiary Button</Button>
          <Button color="red" level="tertiary">Red Tertiary Button</Button>
        </Box>
      </SectionSubSection>

      <SectionSubSection title="Sizes">
        <SectionText>
          Small buttons should be used in small compact spaces, like cards.
        </SectionText>

        <Box alignChildrenVertical="start" flex="horizontal" gap="x4" margin="x2">
          <Button color="blue" level="primary" size="small">Small Primary Button</Button>
          <Button color="blue" level="secondary" size="small">Small Secondary Button</Button>
          <Button color="blue" level="tertiary" size="small">Small Tertiary Button</Button>
        </Box>
      </SectionSubSection>

      <SectionSubSection>
        <SectionText>
          Medium buttons should be the default button for most use cases.
        </SectionText>

        <Box alignChildrenVertical="start" flex="horizontal" gap="x4" margin="x2">
          <Button color="blue" level="primary" size="medium">Medium Primary Button</Button>
          <Button color="blue" level="secondary" size="medium">Medium Secondary Button</Button>
          <Button color="blue" level="tertiary" size="medium">Medium Tertiary Button</Button>
        </Box>
      </SectionSubSection>

      <SectionSubSection>
        <SectionText>
          Large buttons should only be used in wide and open spaces.
        </SectionText>

        <Box alignChildrenVertical="start" flex="horizontal" gap="x4" margin="x2">
          <Button color="blue" level="primary" size="large">Large Primary Button</Button>
          <Button color="blue" level="secondary" size="large">Large Secondary Button</Button>
          <Button color="blue" level="tertiary" size="large">Large Tertiary Button</Button>
        </Box>
      </SectionSubSection>
    </Section>
  );
}
