import * as React from 'react';
import { Text } from '@dishr/design';
import Section from '../Section';
import SectionSubSection from '../SectionSubSection';

export default function Typography() {
  return (
    <Section title="Typography">
      <SectionSubSection title="Sizes">
        <Text
            borderColor="light-gray"
            borderSides="T"
            borderSize="x1"
            margin="x3"
            padding="x3"
            size="x5">
          <Text display="inline" strong>x5</Text> Smoky hot grilled lamb and vegetables in flatbread gets an extra
          kick from spicy harissa.
        </Text>

        <Text
            borderColor="light-gray"
            borderSides="T"
            borderSize="x1"
            margin="x3"
            padding="x3"
            size="x4">
          <Text display="inline" strong>x4</Text> Smoky hot grilled lamb and vegetables in flatbread gets an extra
          kick from spicy harissa.
        </Text>

        <Text
            borderColor="light-gray"
            borderSides="T"
            borderSize="x1"
            margin="x3"
            padding="x3"
            size="x3">
          <Text display="inline" strong>x3</Text> Smoky hot grilled lamb and vegetables in flatbread gets an extra
          kick from spicy harissa.
        </Text>

        <Text
            borderColor="light-gray"
            borderSides="T"
            borderSize="x1"
            margin="x3"
            padding="x3"
            size="x2">
          <Text display="inline" strong>x2</Text> Smoky hot grilled lamb and vegetables in flatbread gets an extra
          kick from spicy harissa.
        </Text>

        <Text
            borderColor="light-gray"
            borderSides="T"
            borderSize="x1"
            margin="x3"
            padding="x3"
            size="x1">
          <Text display="inline" strong>x1</Text> Smoky hot grilled lamb and vegetables in flatbread gets an extra
          kick from spicy harissa.
        </Text>
      </SectionSubSection>
    </Section>
  );
}
