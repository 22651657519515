import * as React from 'react';
import {
  FormInput,
  FormInputHeader,
  FormInputHelp,
  FormInputLabel,
  TextInput,
} from '@dishr/design';
import Section from '../Section';
import SectionText from '../SectionText';

export default function ComponentsForms() {
  return (
    <Section title="Forms">
      <SectionText>
        Form inputs should be used along side a label and
        help where needed.
      </SectionText>

      <SectionText margin="x6">
        <FormInput>
          <FormInputHeader>
            <FormInputLabel>Password</FormInputLabel>
            <FormInputHelp>Forgot your password?</FormInputHelp>
          </FormInputHeader>

          <TextInput type="password" />
        </FormInput>
      </SectionText>
    </Section>
  );
}
