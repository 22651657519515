import * as React from 'react';
import {
  Button,
  Card,
  FormInput,
  FormInputHeader,
  FormInputHelp,
  FormInputLabel,
  Link,
  Text,
  TextInput,
} from '@dishr/design';
import Section from '../Section';
import SectionText from '../SectionText';

export default function ComponentsCards() {
  return (
    <Section title="Cards">
      <SectionText>
        Cards are used to group relevant parts of the UI together and
        elevate the content to focus my attention. Great on a coloured
        background.
      </SectionText>

      <SectionText
          backgroundColor="light-gray"
          margin="x6"
          padding="x6">
        <Card
            backgroundColor="light"
            elevation="x2"
            padding="x6">
          <Text margin="x8" size="x4">Sign in to your account</Text>

          <FormInput margin="x6">
            <FormInputHeader>
              <FormInputLabel>Email</FormInputLabel>
            </FormInputHeader>

            <TextInput />
          </FormInput>

          <FormInput margin="x6">
            <FormInputHeader>
              <FormInputLabel>Password</FormInputLabel>
              <FormInputHelp>Forgot your password?</FormInputHelp>
            </FormInputHeader>

            <TextInput type="password" />
          </FormInput>

          <Button color="blue" fullWidth level="primary">
            Sign in
          </Button>
        </Card>

        <Text align="middle" margin="x6" size="x2" textColor="dark-gray">
          Don't have an account? <Link textColor="blue">Sign up.</Link>
        </Text>
      </SectionText>
    </Section>
  );
}
