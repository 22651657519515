import * as React from 'react';
import { Text, TextProps } from '@dishr/design';

interface SectionTextProps extends TextProps {}

export default function SectionText(props: React.PropsWithChildren<SectionTextProps>) {
  const { margin = 'x3', ...rest } = props;

  return (
    <Text { ...rest } margin={ margin } maxWidth="400px" />
  );
}
