import * as React from 'react';
import { Box, BoxProps, Text } from '@dishr/design';

interface SectionSubSectionProps extends BoxProps {
  title?: string;
}

export default function SectionSubSection(props: React.PropsWithChildren<SectionSubSectionProps>) {
  const { children, title, ...rest } = props;

  return (
    <Box { ...rest } margin="x8" maxWidthCentered>
      { title && (
        <Text margin="x3" size="x3" strong>{ title }</Text>
      ) }

      <Box margin="x3">
        { children }
      </Box>
    </Box>
  );
}
