import * as React from 'react';
import { colorMap, Box, Text, TypeColor } from '@dishr/design';
import Section from '../Section';
import SectionSubSection from '../SectionSubSection';
import SectionText from '../SectionText';

export default function ColorPalette() {
  return (
    <Section title="Color Palette">
      <SectionSubSection>
        <SectionText>
          The 4 shades of dark and light colors should be used with the
          polar combinations to provide clear contrast.
        </SectionText>

        <Box flex="horizontal" gap="x2" margin="x4">
          { (['dark', 'dark-gray', 'gray', 'light-gray', 'light'] as TypeColor[]).map((color) => (
            <Box
                backgroundColor={ color }
                basis="none"
                borderColor={ color === 'light' ? 'light-gray' : undefined }
                borderRadius="x1"
                borderSize={ color === 'light' ? 'x1' : undefined }
                grow
                key={ color }
                padding="x6"
                textColor={ color.startsWith('dark') ? 'light' : 'dark' }>
              <Text size="x2" strong>{ colorMap[color] }</Text>
              <Text size="x2" strong>{ color }</Text>
              <Text size="x2">var(--color-{ color })</Text>
            </Box>
          )) }
        </Box>
      </SectionSubSection>

      <SectionSubSection>
        <SectionText>
          The blue brand colours are to be used as accents and blue action
          indicators. The color is positive so should be used for such positive
          actions.
        </SectionText>

        <Box flex="horizontal" gap="x2" margin="x4">
          { (['blue-dark', 'blue', 'blue-light'] as TypeColor[]).map((color) => (
            <Box
                backgroundColor={ color }
                basis="none"
                borderRadius="x1"
                grow
                key={ color }
                padding="x6"
                textColor="light">
              <Text size="x2" strong>{ colorMap[color] }</Text>
              <Text size="x2" strong>{ color }</Text>
              <Text size="x2">var(--color-{ color })</Text>
            </Box>
          )) }
        </Box>
      </SectionSubSection>

      <SectionSubSection>
        <SectionText>
          The red color should be used for negative actions, something
          the user should not take lightly.
        </SectionText>

        <Box flex="horizontal" gap="x2" margin="x4">
          { (['red'] as TypeColor[]).map((color) => (
            <Box
                backgroundColor={ color }
                basis="none"
                borderRadius="x1"
                grow
                key={ color }
                padding="x6"
                textColor="light">
              <Text size="x2" strong>{ colorMap[color] }</Text>
              <Text size="x2" strong>{ color }</Text>
              <Text size="x2">var(--color-{ color })</Text>
            </Box>
          )) }
        </Box>
      </SectionSubSection>
    </Section>
  );
}
