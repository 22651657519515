import * as React from 'react';
import { Box, Logo, Text } from '@dishr/design';
import ComponentsButtons from './Sections/ComponentsButtons';
import ComponentsCards from './Sections/ComponentsCards';
import ComponentsForms from './Sections/ComponentsForms';
import ComponentsLinks from './Sections/ComponentsLinks';
import ColorPalette from './Sections/ColorPalette';
import Typography from './Sections/Typography';

export default function Root() {
  return (
    <Box padding="x12">
      <Box
          alignChildrenVertical="end"
          flex="horizontal"
          gap="x3">
        <Logo
            backgroundColor="blue"
            borderRadius="x3"
            padding="x1"
            size="3rem"
            textColor="light" />

        <Box textColor="blue-dark">
          <Text size="x4">Style Guide</Text>
        </Box>
      </Box>

      <ColorPalette />
      <Typography />
      <ComponentsButtons />
      <ComponentsCards />
      <ComponentsForms />
      <ComponentsLinks />
    </Box>
  );
}
