import * as React from 'react';
import { Box, Link } from '@dishr/design';
import Section from '../Section';
import SectionSubSection from '../SectionSubSection';
import SectionText from '../SectionText';

export default function ComponentsLinks() {
  return (
    <Section title="Links">
      <SectionText>
        The link component should be used for any action that redirects
        a user to another location or to trigger an optional action where
        a button would not be appropriate.
      </SectionText>

      <SectionSubSection title="Colors">
        <SectionText>
          The standard accented link color should be used on light
          background colors.
        </SectionText>

        <Box flex="horizontal" margin="x6">
          <Box backgroundColor="light-gray" padding="x3">
            <Link
                display="inline-block"
                underline>
              Forgot your password?
            </Link>
          </Box>
        </Box>


        <SectionText>
          The light link color should be used on dark or coloured backgrounds.
        </SectionText>

        <Box flex="horizontal" margin="x6">
          <Box backgroundColor="blue-dark" padding="x3">
            <Link
                display="inline-block"
                textColor="light"
                underline>
              Forgot your password?
            </Link>
          </Box>
        </Box>
      </SectionSubSection>
    </Section>
  );
}
