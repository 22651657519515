import * as React from 'react';
import { Box, BoxProps, Line, Text } from '@dishr/design';

interface SectionProps extends BoxProps {
  title: string;
}

export default function Section(props: React.PropsWithChildren<SectionProps>) {
  const { children, title, ...rest } = props;

  return (
    <Box { ...rest }
        margin="x20"
        maxWidth="800px">
      <Text margin="x6" size="x4" strong textColor="blue-dark">{ title }</Text>
      <Box margin="x6">
        { children }
      </Box>

      <Line />
    </Box>
  );
}
